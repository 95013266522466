@tailwind base;
@tailwind components;
@tailwind utilities;


input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url("/src/assets/images/dashboard/calender.svg");
    width: 20px;
    height: 20px;
}

input[type="checkbox"] {
    color: #C9C9C9
}

.input-form {
    width: 100%;
    height: 55px;
    padding: 0px 20px;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    color: black;
}

.input-form:focus {
    border-color: #7F5FEE;
    background: #F5F2FF;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../src/assets/fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo';
    src: url('../src/assets/fonts/Baloo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@layer base {
    .nav-item {
        @apply mb-4 overflow-hidden
    }

    .nav-link {
        @apply flex transition-all text-gray-2 items-center font-medium hover:text-dark hover:bg-gray-1 text-[15px] gap-2 whitespace-nowrap
    }

    .nav-link.active {
        @apply bg-gray-1 text-dark font-semibold
    }

    .menu-icon {
        @apply text-xl p-4 ml-1
    }

    .nav-link:not(:hover, .active) .menu-icon path {
        @apply fill-[#8B8B8B]
    }

    .btn {
        @apply flex justify-center items-center gap-1 font-bold text-sm rounded-full bg-purple text-white p-2 px-5 hover:scale-105 duration-300 min-w-[140px]
    }

    .large-title {
        /* @apply text-dark font-bold text-[clamp(1.85rem,2.7vw,2.5rem)] */
        @apply text-base md:text-xl text-dark font-bold
    }

    .sub-title {
        @apply text-sm text-grey-6
    }

    .error-css {
        @apply text-left text-[#FF1D48] pt-[2px] sm:pt-0 text-xs sm:text-sm px-2 !important;
    }

    .custom-input {
        @apply border border-light-grey-2 rounded-md p-4 outline-none placeholder:text-grey-3 text-sm inline-block w-full resize-none
    }

    .custom-select {
        @apply border border-light-grey-2 rounded-md pl-4 outline-none placeholder:text-grey-3 text-sm inline-block w-full resize-none
    }

    .custom-label {
        @apply font-medium text-sm text-black-1 mb-1 inline-block
    }

    .tab {
        @apply flex items-center justify-center gap-1 py-4 text-sm font-medium text-[#888888] hover:text-purple w-[92px] md:w-[143px]
    }

    .tab.active {
        @apply text-purple border-b-2 border-current
    }

    .step-check {
        @apply bg-gray-200 border-neutral-300 border rounded-lg flex flex-col justify-center p-4 cursor-pointer relative h-[140px] w-[311px]
    }

    .box-check {
        @apply relative flex-col border border-[#ECECEC] bg-white p-6 text-center flex justify-center items-center rounded-xl w-[218px] h-[225px] cursor-pointer my-4 lg:my-8
    }

    .box-check:has(input[type="radio"]:checked) {
        border-color: #7F5FEE;
    }

    .subjectClass .tiptap,
    .bodyClass .tiptap {
        @apply bg-white outline-none border border-gray-300 w-full min-h-[45px] break-words p-2 rounded focus:border-purple-1 text-base
    }

    .bodyClass .tiptap {
        @apply p-2 min-h-[500px] h-max text-base outline-none border border-gray-300 focus:border-purple-1 rounded
    }

    .subjectClass ol {
        list-style: decimal !important;
    }

    .subjectClass ul {
        list-style: inherit !important;
    }

    .bodyClass ol {
        list-style: decimal !important;
    }

    .bodyClass ul {
        list-style: inherit !important;
    }
}

.tiptap ul,
.tiptap ol {
    padding: 0 1rem;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
    line-height: 1.1;
}

.tiptap code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
}

.tiptap pre {
    background: #b9b9b9;
    color: black;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.tiptap pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.tiptap img {
    max-width: 100%;
    height: auto;
}

.tiptap blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.tiptap hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: revert;
    font-weight: revert;
}


.invite-success-width {
    width: 450px;
    height: 80px;
    box-shadow: 0px 0px 11.1px 0px rgba(0, 0, 0, 0.10) !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: 11px !important;
}

.dropdown__content {
    overflow: hidden;
    transition: max-height 0.6s ease;
    transition: all 0.6s cubic-bezier(0.25, 0.1, 0.87, 1.02);
}

.dropdown {
    transition: background-color 0.6s ease;
}

.dropdown__content .nav-link {
    padding: 0.6rem 0.9rem;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    margin: 5px 0px;
}

.pagination li {
    list-style: none;
    margin: 0 5px;
    display: inline-block;
}

.pagination a {
    padding: 5px 10px;
    text-decoration: none;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.pagination .active a {
    background-color: #7F5FEE;
    color: #fff;
    border: 1px solid #7F5FEE;
}

.pagination a:hover {
    background-color: #e0e0e0;
}

/* Style the checked state of the custom radio button */
.step-check:has(input[type="checkbox"]:checked) {
    background: #fff;
    border-color: #7F5FEE;
}

input:read-only,
input:disabled {
    color: #A1A1A1;
    /* background-color: #ccc; */
}

select:disabled {
    background: none !important;
}

button:disabled {
    opacity: 0.6;
    ;
}

.rcp {
    width: 250px !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 0px solid #e3e3e3 !important;
    width: 179.022px !important;
}

.apexcharts-tooltip {
    border-radius: 10px !important;
    background: rgba(255, 255, 255, 0.20) !important;
    box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.10) !important;
    backdrop-filter: blur(6px) !important;
}

.tox-toolbar__primary> :nth-child(9) .tox-tbtn__select-chevron {
    display: none
}

.dropdown-content {
    z-index: 999 !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    border-radius: 15px !important;
}

div:where(.swal2-icon) {
    margin: 1.5em auto .6em !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    padding: .1em 1em 0 !important;
    font-size: 1.2rem !important;
}

div:where(.swal2-container) .swal2-html-container {
    margin: .4em .4em .2em !important;
    font-size: 0.835rem !important;

}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    font-size: 1rem !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
    font-size: 1rem !important;
}

.gjs-four-color {
    color: #7F5FEE !important;

}

.gjs-four-color-h:hover {
    color: #7F5FEE !important;
}


/* for remove the apearence of number field  */
.type-number::-webkit-inner-spin-button,
.type-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
